import React from 'react'
import '../Reasons/Reasons.css'
import nike from '../../assets/banners/nike.png'
import adidas from '../../assets/banners/adidas.png'
import nb from '../../assets/banners/nb.png'
import tick from '../../assets/banners/tick.png'
import image1 from '../../assets/banners/image1.png'
import image2 from '../../assets/banners/image2.png'
import image3 from '../../assets/banners/image3.png'
import image4 from '../../assets/banners/image4.png'




const Reasons = () => {
  return (
    <>
    <section>
    <div className="reasons" id="reasons">
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image4} alt="" />
        <img src={image3} alt="" />
      </div>
      <div className="right-r">
        <span>Some Reasons</span>
        <div>
          <span className="stroke-text">Why</span>
          <span> Choose Us?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt=""></img>
            <span>Best Advice from industry experts</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Proper monitoring of diet</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>continious assesment on physique</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Diet chart </span>
          </div>
        </div>
        {/* <span
          style={{
            color: "var(--gray)",
            fontWeight: "normal",
          }}
        >
          OUR Partners
        </span> */}
        {/* <div className="partners">
          <img src={nb} alt="" />
          <img src={adidas} alt="" />
          <img src={nike} alt="" />
        </div> */}
      </div>
    </div>
    </section>
    </>
  );
}

export default Reasons