import React from 'react'
import './Footer.css'
import facebook from '../../assets/banners/facebook.png';
import instagram from '../../assets/banners/instagram.png';
import twitter from '../../assets/banners/twitter.png';
import youtube from '../../assets/banners/youtube.png';
import MuscleMitraLogo from "../../assets/images/Logo MM (2).png"









const Footer = () => {
  const year = new Date().getFullYear();
  console.log(year)
  return (
    <>
      <div className="footer-container">
        <hr />
        <div className="footer">
          <div className="social-links">
            <a href="https://www.facebook.com/Musclemitra" target="blank">
              <img src={facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/musclemitra/" target="blank">
              <img src={instagram} alt="" />
            </a>
            <a href="https://www.twitter.com/Musclemitra" target="blank">
              <img src={twitter} alt="" />
            </a>
            <a href="https://www.youtube.com/Musclemitra" target="blank">
              <img src={youtube} alt="" />
            </a>
          </div>
          <div className="logo-f">
            <img src={MuscleMitraLogo} alt="" />
          </div>
        </div>
        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
      </div>

      <footer>
        © {year} Muscle Mitra
        <p>Powered by<a target='blank'  href="https://insarthitechnologies.com"> Insarthi Technologies</a></p>
      </footer>
    </>
  );
}

export default Footer