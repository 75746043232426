import React from 'react'
import BlogCss from '../Blog/Blog.css';
import { blogs } from "../../Data/blogs.js";

const Blog = () => {
  return (
    <>
      <section>
        {/* <!-- BLOG --> */}
        <div className="container blogs" style={{textAlign : "center"}}>    
          <h2 className="stroke-text">Our Latest Blogs</h2>
          <div className="card-container">
            {blogs.map((blog, i) => (
            <div className="card" key={i}>
              <img src={blog.img} alt="" />
              <div className="content">
                <h3>{blog.title}</h3>
                <p>
                  {blog.shortDiscription}
                </p>
              </div>
              <div className="hover-content">
              <h3 className='heading'>{blog.title}</h3>
                <p>
                  {blog.content}
                </p>
                  <a href={blog.link}target='blank'>Read More...</a>
              </div>
            </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;