import image1 from "../assets/banners/t-image1.png";
import image2 from "../assets/banners/t-image2.jpg";
import image3 from "../assets/banners/t-image3.jpg";
import img4 from "../assets/banners/img_4.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the MuscleMitra and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Ramesh',
    status : 'Customer'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'Suresh',
    status: 'Customer'
  },
  {
    image : image3,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'Pappu Pejer',
    status: "CUSTOMER"
  },
  {
    image : img4,
    review: 'kya halchal bhidu ',
    name: 'Pappu pankaj',
    status: "CUSTOMER"
  }
];
