import React, { useState } from 'react';
// import Logo from '../../assets/images/logo-no-background.png'
import HeaderCss from '../Header/Header.css';
import MuscleMitraLogo from "../../assets/images/Logo MM (2).png";
import Hamburger from "../../assets/banners/bars.png";
import { Link } from 'react-scroll';


const Header = () => {

  const mobile =  window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);


  return (
    <div className="header">
      <img className="logo" src={MuscleMitraLogo} alt="company logo" />
      {menuOpened === false && mobile === true  ? (
        <div
          onClick={() => setMenuOpened(true)}
          style={{
            backgroundColor: "var(--appColor)",
            padding: "0.55rem",
            borderRadius: "5px",
          }}
        >
          <img
            src={Hamburger}
            style={{ width: "1.5rem", height: "1.5rem" }}
            alt="Hamburger"
          />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
          <Link
              onClick={() => setMenuOpened(false)}
              activeClass='active'
              to='home'
              span={true}
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li><Link
              onClick={() => setMenuOpened(false)}
              to='reasons'
              span={true}
              smooth={true}
            >
              About
            </Link></li>
          <li><Link
              onClick={() => setMenuOpened(false)}
              to='programs'
              span={true}
              smooth={true}
            >
              Programs
            </Link></li>
          <li><Link
              onClick={() => setMenuOpened(false)}
              to='training'
              span={true}
              smooth={true}
            >
              Training
            </Link></li>
          <li><Link
              onClick={() => setMenuOpened(false)}
              to='blogs'
              span={true}
              smooth={true}
            >
              Blog
            </Link></li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to='testimonials'
              span={true}
              smooth={true}
            >
              Testimonials
            </Link>
          </li>

          <li><Link
              onClick={() => setMenuOpened(false)}
              to='join'
              span={true}
              smooth={true}
            >
              Contact
            </Link></li>
        </ul>
      )}
    </div>
  );
}

export default Header