import React from 'react'
import "../Programs/Programs.css"
import {programsData} from '../../Data/programsData'
import rightArrow from '../../assets/banners/rightArrow.png'




const Programs = () => {
  return (
    <><section>
<div className="programs" id="programs">
    {/* Header */}
    <div className="programs-header">
        <span className='stroke-text'>Explore our</span>
        <span>Programs </span>
        <span className='stroke-text'>to Shape you</span>
    </div>

    <div className="program-categories">
        {programsData.map((program) => ( 
            <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">
                    <a href=""><span>{program.btnName}{program.link}</span></a>
                    <img src={rightArrow} alt="" />
                </div>
            </div>
        ))}

    </div>
</div>
</section>
</>
  )
}

export default Programs