import React from 'react'
import './Join.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser';


const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_gp5pemf', 'template_sq11pt8', form.current, 'vnSd37VkLpIb4giMH')
          .then((result) => {
              // console.log(result.text);
          }, (error) => {
              // console.log(error.text);
          });
      };
  return (
    <><section>
    <div className="join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className='stroke-text'>Ready to</span>
          <span>Level up</span>
        </div>
        <div>
          <span>Your Body</span>
          <span className='stroke-text'>With us</span>
        </div>
      </div>
      <div className="right-j">
        <div className='form-group'>

        <form ref={form} onSubmit={sendEmail} action="" className="email-container">
            <input type="text" placeholder='Enter your name' name='user_name'/>
            <input type="number" placeholder='Enter your Number' name='user_number'/>
            <input type="email" name='user_email' placeholder='Enter your Email' />
            <input type="text" name='user_city' placeholder='Enter your City Name' />
            <textarea name="user_message" cols="30" rows="10" placeholder='Write the text here'></textarea>
            <button type='submit' className="btn btn-j ">Join now</button>
            
        </form>
        </div>

      </div>
    </div>
    </section></>
  );
}

export default Join