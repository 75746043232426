import blogImg1 from '../../src/assets/images/suppliments.webp'
import blogImg2 from '../../src/assets/images/blogImg2.webp'
import blogImg3 from '../../src/assets/images/blogImg3.webp'
import blogImg4 from '../../src/assets/images/blogImg4.webp'
import blogImg5 from '../../src/assets/images/blogImg5.webp'
import blogImg6 from '../../src/assets/images/blogImg6.webp'



export const blogs= [
    {
        id: 1,
        title: 'Blog Title',
        img: blogImg1,
        shortDiscription:'Lorem ipsum dolor sit amet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        Heading:'Lorem ipsum dolor sit aectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        content:'Lorem ipsum dolor sonsectetur adipisicing molestias nemo autem volupt',
        link: "/",
    },
    {
        id: 2,
        title: 'Blog Title',
        img: blogImg2,
        shortDiscription:'Lorem ipsum dolor sit amet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        Heading:'Lorem ipsum ur  elit Quisquam quod debitis molestias nemo autem volupt',
        content:'Lorem sicing elit Quisquam quod debitis molestias nemo autem volupt',
        link: "/",
    },
    {
        id: 3,
        title: 'Blog Title',
        img: blogImg3,
        shortDiscription:'Lorem ipsum dolor sit amet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        Heading:'Lorem ipsum dolomet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        content:'Lorem ipsum adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        link: "/",
    },
    {
        id: 4,
        title: 'Blog Title',
        img: blogImg4,
        shortDiscription:'Lorem ipsum dolor sit amet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        Heading:'Lorem ipsum t Quisquam quod debitis molestias nemo autem volupt',
        content:'Lorem ipsumelit Qebitis molestias nemo autem volupt',
        link: "/",
    },
    {
        id: 5,
        title: 'Blog Title',
        img: blogImg5,
        shortDiscription:'Lorem ipsum dolor sit amet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        Heading:'Lorem ipsum doletur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        content:'Lorem ipsumur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        link: "/",
    },
    {
        id: 6,
        title: 'Blog Title',
        img: blogImg6,
        shortDiscription:'Lorem ipsum dolor sit amet consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        Heading:'Lorem ipsum consectetur adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        content:'Lorem ipsum adipisicing elit Quisquam quod debitis molestias nemo autem volupt',
        link: "/",
    },
];


