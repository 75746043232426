import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import HeroImg from "../../assets/banners/hero_image.png";
// import HeroImg2 from "../../assets/banners/girlgym.png";
import HeroImgBack from "../../assets/banners/hero_image_back.png";
import Heart from "../../assets/banners/heart.png";
import Calories from "../../assets/banners/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter"





const Hero = () => {

  const transition = {type: 'spring', duration : 3};
  const mobile =  window.innerWidth <= 768 ? true : false;

  return (
    <>
    <section>
    <div className="hero" id='home'>
      <div className="blur blur-hero"></div>
      <div className="left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div
          initial = {{left: mobile? "178px" : "238px"}}
          whileInView = {{left: "8px"}}
          transition = {{...transition, type: "tween"}}
          ></motion.div>
          <span>The best fitness club in the town</span>
        </div>
        {/* HEro text Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>Join the revolution of being fit & Healthy</span>
          </div>
        </div>

        {/* figures section  */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter start = {100} preFix="+" delay= "4" end={140}></NumberCounter>
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>
            <NumberCounter start = {600} preFix="+" delay= "4"end={740}></NumberCounter>
              </span>
            <span>Members Joined</span>
          </div>
          <div>
            <span>
            <NumberCounter start = {1} preFix="+" delay= "4"end={50}></NumberCounter>

            </span>
            <span>Fitness Programs</span>
          </div>
        </div>

        {/* hero buttons */}
        

        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join now</button>

        <motion.div 
        initial = {{right: "-1rem"}}
        whileInView={{right: "4rem"}}
        transition={transition}
        className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart rate </span>
          <span>116 Bpm</span>
        </motion.div>
        {/* hero imgs */}

        <img src={HeroImg} alt="heroIMg" className="hero-image" />
        {/* <img src={HeroImg2} alt="hero image" className='hero-image' /> */}
        <motion.img
        initial = {{right: "11rem"}}
        whileInView={{right: "20rem"}}
        transition={transition}
        src={HeroImgBack} alt="backhero img" className="hero-image-back" />
        {/* calories */}
        <motion.div 
         initial = {{right: "37rem"}}
         whileInView={{right: "28rem"}}
         transition={transition}
         
        className="calories">
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>220 Kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
    <div className="blur blur-hero"></div>
    </section>
    </>
  );
}

export default Hero;